import { qs, qsa } from '../utils/lib'
import { gsap } from 'gsap'
import { indexOf, takeRightWhile } from 'lodash';

export default class Slider {
    constructor(el) {
        this.el = el
        this.next = this.el.querySelector('.slider_arrows_next');
        this.prev = this.el.querySelector('.slider_arrows_prev');
        this.status = 0;
        this.active_slide = 0;
        this.slides = qsa('.slider_track_item', this.el);
        this.isAnimating = false;
        this.slideWidth = this.slides[0].offsetWidth
        this.track = this.el.querySelector('.slider_track')
        this.arrows = this.el.querySelector('.slider_arrows')
        this.isDevice = window.innerWidth < 1024
        this.margin = window.innerWidth > 640 ? 16 : 0
        this.offset = 0
        this.dir = 1
        this.autoplay = el.dataset.autoplay
    }
    init() {
        this.setLayerBtn()
        if ( this.autoplay ) {
            this.interval = setInterval( () => {
                if ( this.status >= this.slides.length - 1 ) {
                    this.dir = 0
                } 
                if ( this.status == 0 ) {
                    this.dir = 1
                } 
                
                if  (this.dir == 1) {
                    this.goNext()
                } else {
                    this.goPrev()
                }
            }, 4000);
        }
       
        this.checkStatus()
        if (this.el.dataset.slider == 'video') {
            this.setPosition()
        }
        if (this.el.dataset.slider == 'texts') {
            this.margin = 0
        }   
        if (this.el.dataset.slider == 'large') {
            this.slides.forEach((slide,i) => {
                slide.addEventListener('click', (e) => {
                    i == 0 && slide.classList.add('active')
                    if (i > this.status) {
                        e.preventDefault()
                        if ( this.interval ) {
                            clearInterval(this.interval);
                        }
                        this.goNext()
                    } else {
                        e.preventDefault()
                        if ( this.interval ) {
                            clearInterval(this.interval);
                        }
                        this.goPrev()
                    }
                })
            })
        }              
        this.prev && this.prev.classList.add('disabled')
        if (this.slides.length == 1) {
            this.next && this.next.classList.add('disabled')
        }
        this.slides[0].classList.add('active')
        this.next && this.next.addEventListener('click', () => {
            clearInterval(this.interval);
            this.goNext()
        })
        this.prev && this.prev.addEventListener('click', () => {
            clearInterval(this.interval);
            this.goPrev()
        })
        window.addEventListener('resize', () => {
            this.resizeEvents()
        })
    }
    setLayerBtn(){
        this.slides.forEach((slide,i) => {
            const layer = slide.querySelector('.slider_track_item_wrapper_btn')
            layer && layer.addEventListener('click', (e) => {
                i == 0 && layer.classList.add('active')
                if (i > this.status) {
                    e.preventDefault()
                    this.goNext()
                } else {
                    e.preventDefault()
                    this.goPrev()
                }
            })
        })
    }
    resizeEvents(){
        this.margin = window.innerWidth > 640 ? 16 : 0
        let offset = window.innerWidth > 640 ? 0.25 : 0
        this.slideWidth = this.slides[0].offsetWidth
        if (this.el.dataset.slider == 'video') {
            gsap.set(this.track, {
                x: -(this.slideWidth * this.status - this.slideWidth * offset)
            })
            gsap.set(this.arrows, {
                x: this.slideWidth * offset
            })            
        } else {
            gsap.set(this.track, {
                x: -(this.slideWidth * this.status)
            })
        }


    }
    setPosition() {
        this.margin = window.innerWidth > 640 ? 16 : 0
        gsap.set([this.track, this.arrows], {
            x: window.innerWidth < 1024 ? 0 : this.slideWidth * 0.25
        })
    }
    goNext() {
        if (this.isAnimating) {
            return false
        } else {
            this.status++;
            this.slides.forEach((slide, i) => {
                if (i == this.status) {
                    slide.classList.add('active')
                } else {
                    slide.classList.remove('active')
                }
            })
            this.isAnimating = true
            gsap.to(this.track, {
                duration: 0.3,
                x: `-=${this.slideWidth}`,
                onComplete: () => {
                    this.isAnimating = false
                }
            })
            this.checkStatus()
        }
    }
    goPrev() {
        if (this.isAnimating || this.status == 0) {
            return false
        } else {
            this.isAnimating = true
            this.status--;
            this.slides.forEach((slide, i) => {
                if (i == this.status) {
                    slide.classList.add('active')
                } else {
                    slide.classList.remove('active')
                }
            })
            gsap.to(this.track, {
                duration: 0.3,
                x: `+=${this.slideWidth}`,
                onComplete: () => {
                    this.isAnimating = false
                }
            })
            this.checkStatus()
        }
    }
    checkStatus() {
        if (this.status >= this.slides.length - 1) {
            this.next.classList.add('disabled');
        } else {
            this.next.classList.remove('disabled');
        }

        if (this.status > 0) {
            this.prev.classList.remove('disabled');
        } else {
            this.prev.classList.add('disabled');
        }
        this.slides.forEach((slide, i) => {
            if (i == this.status) {
                const layer = slide.querySelector('.slider_track_item_wrapper_btn')
                slide.classList.add('active')
                layer && layer.classList.add('active')
            } else {
                const layer = slide.querySelector('.slider_track_item_wrapper_btn')
                slide.classList.remove('active')
                layer && layer.classList.remove('active')
            }
        })        
    }
}