import '../../scss/application.scss'
import HomeAccordion from '../components/home_accordion'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/all';
import { qs, qsa } from '../utils/lib'
import RegularCarousel from '../components/regular_carousel'
import Header from '../components/header'
import SingleImgCarousel from '../components/single_img_carousel'
import RightSideCarousel from '../components/right_side_carousel'
import ProjectDetailsAccordion from '../components/project_details_accordion'
import MagazineCarousel from '../components/magazine_carousel'
import IntersectionObs from '../components/intersection_obs'
import Search from '../components/search.jsx'
import React from 'react'
import ReactDOM from 'react-dom'
import Sidebar from '../components/sidebar_fixed'
import Menu from '../components/menu'
import Slider from '../components/slider'
import ModalVideo from "modal-video"
import MagazineNotes from '../components/magazine_notes'
import Sticky from 'sticky-js'
import LabsHero from '../components/labs_hero'
import Selectr from "mobius1-selectr"
import BlankLinks from '../components/blank_links'
import PrivateArea from '../components/private_area'
import feather from 'feather-icons'
//import ResearchProjects from '../components/research_projects'



document.addEventListener('DOMContentLoaded', () => {
    feather.replace()
    gsap.registerPlugin(ScrollToPlugin);
    if (window.location.hash) {
        const hash = window.location.hash;
        const offset = window.pageYOffset + document.querySelector(hash).getBoundingClientRect().top - 100
        console.log(offset)
        gsap.to(window, 1,{scrollTo:{y:offset}});
       
    }
    const p = qs('.private_area')
    if ( p ) {
        new PrivateArea()
    }

    

    LabsHero()
    BlankLinks()
    

    
    
  
    const sels = qsa('select')
    sels.forEach((el) => {
        new Selectr(el,{
            searchable: p ? true : false
        });
    })
    
    
    const mag_articles = qsa('.magazine_article_text')
    mag_articles.forEach((mag) => {
        const app = new MagazineNotes(mag)
        app.init()
    })
    const videos = qsa('.js-modal-btn')
    videos.forEach((el) => {
        const v = new ModalVideo("#"+ el.getAttribute("id"));
    })
    
    const h_acc = qsa('.js-home_accordion', document)
    h_acc.forEach((acc) => {
        const h_acc_app = new HomeAccordion(acc)
        h_acc_app.init()
    })
    const regular_carousels = qsa('.js-regularcarousel', document)
    regular_carousels.forEach((car) => {
        const carousel_app = new RegularCarousel(car)
        carousel_app.init()
    })
    const header = qs('.js-header')
    if (header) {
        const header_app = new Header(header)
        header_app.init()
    }
    const single_img_carousel = qsa('.js-single_img_carousel', document)
    single_img_carousel.forEach((car) => {
        const singleImgCarousel = new SingleImgCarousel(car)
        singleImgCarousel.init()
    })
    const right_side_carousel = qsa('.js-right_side_carousel', document)
    right_side_carousel.forEach((car) => {
        const rightSideCarousel = new RightSideCarousel(car)
        rightSideCarousel.init()
    })
    const proj_det_acc = qsa('.js-project_details_accordion', document)
    proj_det_acc.forEach((acc) => {
        const proj_det_acc_app = new ProjectDetailsAccordion(acc)
        proj_det_acc_app.init()
    })
    const magazine_carousel = qsa('.js-magazine-carousel', document)
    magazine_carousel.forEach((acc) => {
        const magazine_carousel_app = new MagazineCarousel(acc)
        magazine_carousel_app.init()
    }) 
    const menu = qs('.menu')
    if (menu) {
        const menu_app = new Menu(menu)
        menu_app.init()
    }
    const sliders = qsa('.js-slider')
    sliders.forEach((slider) => {
        const s_app = new Slider(slider)
        s_app.init()
    })
    /*    SCROLL ANIMATION    */
    const sidebar = qs('.sticky_sidebar')
    if (sidebar) {
        const s_app = new Sidebar(sidebar)
        s_app.init()
    }
    const sticky = new Sticky('.sticky_sidebar');


    const intersection_obs = qsa('.js-animation', document)
    intersection_obs.forEach((acc) => {
        const intersection_obs_app = new IntersectionObs(acc)
        intersection_obs_app.init()
    })    

    const selects = qsa('.js_select_submit')
    selects.forEach((select) => {
        select.addEventListener("change",() => {
            window.location = select.value
        })
    })

    

    const sss = qs('.jsx-search')
    if ( sss ) {

        ReactDOM.render( <Search />, qs('.jsx-search'))
    }
  
})



